import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { createMask } from '@ngneat/input-mask';
import { forkJoin, map, mergeMap, of, takeWhile } from 'rxjs';
import { CompanyInterface } from 'src/app/interfaces/company.interface';
import { AuthService } from 'src/app/services/auth.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { InstallmentInterface } from '../../interfaces/invoice.interface';
import { DraweeService } from '../../services/drawee.service';
import { DeleteModalInstallmentComponent } from '../delete-modal-installment/delete-modal-installment.component';
import {
  DefaultDocumentInterface,
  DocumentInterface,
} from '../document-dropdown/document.interface';

@Component({
  selector: 'app-invoice-data-form',
  templateUrl: './invoice-data-form.component.html',
  styleUrls: ['./invoice-data-form.component.scss'],
})
export class InvoiceDataFormComponent implements OnInit {
  form: FormGroup;
  invoiceTypeOptions = ['DMR', 'CTE', 'CHQ', 'DSR'];
  currencyInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    digitsOptional: false,
    min: 0,
    prefix: 'R$ ',
    placeholder: '0',
  });
  installments: { number: string | number; value: string; date: string }[] = [];
  showInvoiceNumber = false;
  isLoading = false;
  formSaved = false;
  registeredDrawee?: CompanyInterface | null;
  defaultDocuments: DefaultDocumentInterface[] = []; // o que vem da API quando é xml
  documents: DocumentInterface[] = [];
  isLoadingDocs = false;
  docsDisabled = true;

  @Output() registered = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private draweeService: DraweeService,
    private notifier: NotifierService,
    private authService: AuthService
  ) {
    this.form = this.fb.group({
      invoice: this.fb.group({
        number: ['', [Validators.required]],
        type: ['', [Validators.required]],
        key: ['', [Validators.required]],
      }),
      installment: this.fb.group({
        value: ['', [Validators.required]],
        date: ['', [Validators.required]],
      }),
    });

    this.listenInvoiceTypeChanges();
    this.listenRegisteredDraweeChanges();
    this.listenCteDocumentChanges();
  }

  ngOnInit(): void {
    this.draweeService
      .getInvoice()
      .pipe(takeWhile((invoice) => !!invoice))
      .subscribe((invoice) => {
        this.form.get('invoice')?.patchValue({
          key: invoice?.key,
          type: invoice?.type,
          number: invoice?.number,
        });

        if (invoice?.id) {
          this.draweeService.canAddInvoice.next(true);
          this.draweeService.invoiceSaved.next(true);
          const installments = invoice.installments ?? [];
          this.defaultDocuments =
            invoice.documents?.map((document) => ({
              name: document.fileName,
              deletable: true,
              id: document.id,
            })) ?? [];

          this.installments =
            installments?.map(
              ({ numberInstallment, dueDate, totalAmount }) => ({
                number: numberInstallment,
                value: `${totalAmount}`,
                date: dueDate,
              })
            ) ?? [];

          this.formSaved = true;
          this.draweeService.registeredInvoices.next(
            this.draweeService.registeredInvoices.getValue().concat(invoice.id)
          );

          this.disableFields();
          this.docsDisabled = false;
        }
      });
  }

  listenInvoiceTypeChanges() {
    this.form
      .get('invoice')
      ?.get('type')
      ?.valueChanges.subscribe((invoiceType) => {
        if (invoiceType === 'DMR' || invoiceType === 'CTE') {
          this.form
            .get('invoice')
            ?.get('key')
            ?.setValidators([Validators.required]);
          this.showInvoiceNumber = true;
          return;
        } else {
          this.form.get('invoice')?.get('key')?.clearValidators();
          this.form.get('invoice')?.get('key')?.setValue(null);
          this.form.get('invoice')?.get('key')?.updateValueAndValidity();
        }
        this.showInvoiceNumber = false;
      });
  }

  listenRegisteredDraweeChanges() {
    this.draweeService.registeredDrawee.subscribe({
      next: (registeredDrawee) => {
        if (this.formSaved) return;
        if (!registeredDrawee) {
          this.disableFields();
        }
        if (registeredDrawee) {
          this.enableFields();
          this.registeredDrawee = registeredDrawee;
        }
      },
    });
  }

  listenCteDocumentChanges() {
    this.draweeService.invoiceDocument.subscribe((file) => {
      if (file) this.documents.push({ file, deletable: false });
    });
  }

  get formInvalid() {
    return this.form.get('invoice')?.invalid || this.installments.length === 0;
  }

  get installmentForm() {
    return this.form.get('installment') as FormGroup;
  }

  onDocumentsChange(documents: DocumentInterface[]) {
    this.documents = [...this.documents, ...documents];
  }

  deleteDefaultDocument(params: { id: string; index: number }) {
    // quando for XML
    const { id, index } = params;
    this.draweeService.deleteDoc(id).subscribe({
      next: () => {
        this.notifier.showNotification({
          message: 'Documento excluído',
          messageType: 'successNotifier',
        });

        this.defaultDocuments.splice(index, 1);
      },
      error: () => {
        this.notifier.showNotification({
          message: 'Falha ao excluir documento',
          alternative: 'Tente novamente',
          messageType: 'errorNotifier',
        });
      },
    });
  }

  addInstallment() {
    if (this.form.get('installment')?.invalid) return;

    const { value, date } = this.installmentForm?.value;

    this.installments.push({
      number: this.installments.length + 1,
      value: value.replace('R$ ', '').replaceAll('.', '').replace(',', '.'),
      date: new Date(date)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('/'),
    });

    this.installmentForm.reset();
  }

  onDeleteInstallment(index: number) {
    const dialogRef = this.dialog.open(DeleteModalInstallmentComponent, {
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.delete) {
        this.installments.splice(index, 1);
      }
    });
  }

  disableFields() {
    this.form.disable();
    this.docsDisabled = true;
  }

  enableFields() {
    this.form.enable();
    this.docsDisabled = false;
  }

  resetForm() {
    this.form.reset();
    this.documents = [];
  }

  onSubmit() {
    const draweeCnpj = this.registeredDrawee?.cnpj;
    if (!draweeCnpj) return;

    this.isLoading = true;

    const companyCnpj = this.authService.getAuth().cnpj;

    const data: InstallmentInterface = {
      cnpj: companyCnpj,
      notas: [
        {
          tipoOperacao: this.form.get('invoice')?.value.type,
          chaveNota: this.form.get('invoice')?.value.key,
          numeroNota: this.form.get('invoice')?.value.number,
          sacado: {
            cnpj: draweeCnpj,
            email: this.registeredDrawee?.mailInformed!,
            telefone: this.registeredDrawee?.phoneInformed!,
          },
          parcelas: this.installments.map((i) => ({
            cnpj: draweeCnpj,
            data: i.date.split('/').reverse().join('-'),
            numeroParcela: i.number.toString(),
            valor: i.value,
          })),
        },
      ],
    };

    this.draweeService
      .createInvoice(data)
      .pipe(
        mergeMap((invoiceId) => {
          const documentUploads = this.documents.map((document) =>
            this.draweeService.uploadDocument(invoiceId, document.file)
          );

          if (documentUploads.length) {
            return forkJoin(documentUploads).pipe(map(() => invoiceId));
          }
          return of(invoiceId);
        })
      )
      .subscribe({
        next: (id) => {
          this.isLoading = false;

          this.draweeService.invoice.next(null);
          this.formSaved = true;
          this.draweeService.registeredInvoices.next(
            this.draweeService.registeredInvoices.getValue().concat(id)
          );

          this.notifier.showNotification({
            message: 'Nota salva com sucesso',
            messageType: 'successNotifier',
          });

          this.draweeService.canAddInvoice.next(true);
          this.draweeService.invoiceSaved.next(true);

          this.registered.emit(true);
          this.disableFields();
        },
        error: (response) => {
          this.isLoading = false;

          let message = 'Erro ao importar nota';

          if (response?.error?.customMessage === 'NOTE_ALREADY_REGISTERED') {
            this.draweeService.invoice.next(null);
            this.draweeService.retrySubmit.next(true);

            this.installments = [];
            this.formSaved = false;
            this.resetForm();
            this.disableFields();

            message = 'Nota já cadastrada';
          }

          this.notifier.showNotification({
            message,
            messageType: 'errorNotifier',
          });
        },
      });
  }
}
