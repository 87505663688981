<div class="main-container">
  <div class="title"></div>
  <div class="table-header-container">
    <span class="example-spacer"></span>
  </div>

  <div class="header">
    <div class="label-container">
      <p class="label">Comprador</p>
    </div>

    <div class="label-container">
      <p class="label">Aditivo</p>
    </div>

    <div class="label-container">
      <p class="label">Valor Bruto</p>
    </div>

    <div class="label-container">
      <p class="label">Valor Líquido</p>
    </div>
    <div class="label-container">
      <p class="label">Ação</p>
    </div>
    <div class="space_84"></div>
  </div>
  <div class="table-content-container">
    <mat-accordion *ngFor="let document of operationList">
      <mat-expansion-panel>
        <mat-expansion-panel-header class="dropdown-header">
          <div class="rows">
            <div class="dropdown-label-container">
              <p class="dropdown-label">
                {{ document.installments[0].nameBuyer || "--" }}
              </p>
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [disabled]="row.hasPending"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>
            </div>
            <div
              class="dropdown-label-container"
              [matTooltipPosition]="'left'"
              matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
              [matTooltipDisabled]="!document.installments[0].hasPending"
            >
              <p>
                {{ document.additive }}
              </p>
            </div>
            <div class="dropdown-label-container">
              <p class="dropdown-label">
                {{ document.totalAmount | currency : "BRL" }}
              </p>
            </div>
            <div class="dropdown-label-container">
              <p class="dropdown-label">
                {{ document.amountToPay || 0 | currency : "BRL" }}
              </p>
              <p class="dropdown-label"></p>
            </div>
            <div class="dropdown-label-container">
              <a
                href="https://cloud.bry.com.br/scad/workflows/pendente"
                target="_blank"
                class="link"
                >Assinar</a
              >
            </div>
            <div class="space_70"></div>
          </div>
        </mat-expansion-panel-header>

        <section class="container-table mat-elevation-z8" tabindex="0">
          <div class="table-container">
            <table mat-table [dataSource]="document.dataSource">
              <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef>Download</th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  (click)="
                    getDownloadFilesOperation(row.id, row.numberInvoice!)
                  "
                >
                  <button class="table-button" mat-icon-button>
                    <mat-icon>download</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="sacados">
                <th mat-header-cell *matHeaderCellDef>Sacados</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameSacado }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Broker">
                <th mat-header-cell *matHeaderCellDef>Broker</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameBroker }}
                </td>
              </ng-container>
              <ng-container matColumnDef="OperationType">
                <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
                <td mat-cell *matCellDef="let element">
                  {{ getNameTypeOperation(element.operationType) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dueDateEnd">
                <th mat-header-cell *matHeaderCellDef>Vencimento</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dueDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="totalAmount">
                <th mat-header-cell *matHeaderCellDef>Valor de Face</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalAmount || 0 | currency : "BRL" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="valueLiquid">
                <th mat-header-cell *matHeaderCellDef>Valor líquido</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amountToPay || 0 | currency : "BRL" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="registerDrawee">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button>
                    <mat-icon mat-button>assignment</mat-icon>
                  </button>
                </th>

                <td mat-cell *matCellDef="let row">
                  <button
                    mat-icon-button
                    (click)="openDialogRegisterDrawee(row)"
                    matTooltip="Visualizar ou editar cadastro do sacado"
                  >
                    <mat-icon>assignment</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </section>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner class="spinner" color="accent"></mat-spinner>
    </div>
    <div
      class="container-blankslate"
      *ngIf="!isLoading && operationList.length == 0"
    >
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </div>
  <div class="informations-status">
    <div class="content-status">
      <p class="text">
        O que significa o status:
        <span class="bold">Aguardando assinatura?</span>
      </p>
      <p class="text">
        Significa que você precisa assinar os documentos da operação para
        receber o valor pago da antecipação.
      </p>
      <p class="text">
        <span class="bold">Obs:</span> Clique em “Assinar” para ser
        redirecionado ao portal de assinatura Bry. É necessário fazer um
        cadastro gratuito uma única vez.
      </p>
      <p>
        <a
          href="https://blogbroadfactor.com/blog/o-que-voce-esta-assinando/"
          class="link"
          target="_blank"
          >Entenda o que está assinando.</a
        >
      </p>
    </div>
  </div>
</div>
