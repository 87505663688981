<div class="main-container">
  <app-filters
    tabStatus="IN_ANALYSIS"
    (filterEvent)="handleSubmit($event)"
  ></app-filters>
  <section
    class="table-container mat-elevation-z8"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getCustomers()"
    [scrollWindow]="false"
  >
    <table mat-table [dataSource]="leads">
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>
          <button class="table-button" mat-icon-button>
            <mat-icon>download</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            (click)="getDownloadFilesOperation(row.id, row.numberInvoice!)"
          >
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameSacado ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Broker">
        <th mat-header-cell *matHeaderCellDef>Broker</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBroker ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="OperationType">
        <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
        <td mat-cell *matCellDef="let element">
          {{ getNameTypeOperation(element.operationType) ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="comprador">
        <th mat-header-cell *matHeaderCellDef>Comprador</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBuyer }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Parcela">
        <th mat-header-cell *matHeaderCellDef>Parcela</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInstallment ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDateEnd">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberInvoice">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInvoice ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalAmount | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valueLiquid">
        <th mat-header-cell *matHeaderCellDef>
          Valor líquido
          <mat-icon
            matTooltip="Valor líquido considerando apenas o deságio. O valor pode mudar devido ao prazo de vencimento"
            >help</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.amountToPay | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="registerDrawee">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button>
            <mat-icon mat-button>assignment</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            (click)="openDialogRegisterDrawee(row)"
            matTooltip="Visualizar ou editar cadastro do sacado"
          >
            <mat-icon>assignment</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [matTooltipPosition]="'left'"
        matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
        [matTooltipDisabled]="!row.hasPending"
        [ngClass]="{ 'table-text-warning': row.hasPending }"
      ></tr>
    </table>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner color="accent"></mat-spinner>
    </div>
    <div class="container-blankslate" *ngIf="!isLoading && leads.length == 0">
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </section>

  <div class="informations-status">
    <div class="content-status">
      <p class="text">
        O que significa o status: <span class="bold">Em análise?</span>
      </p>
      <p class="text">
        Significa que as NFS-e estão sendo analisadas para antecipação por um
        dos parceiros da Broadfactor.
      </p>
      <p class="text">
        <span class="bold">Obs:</span> Não é necessária nenhuma ação. Após isso,
        o processo terá continuidade.
      </p>
      <p>
        <a
          href="https://blogbroadfactor.com/blog/o-que-os-compradores-analisam/"
          class="link"
          target="_blank"
          >Saiba o que os compradores analisam.</a
        >
      </p>
    </div>
  </div>
</div>
