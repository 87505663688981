<div class="main-container">
  <app-filters
    [tabStatus]="customerStatus"
    (filterEvent)="handleSubmit($event)"
  ></app-filters>
  <section
    class="table-container mat-elevation-z8"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getCustomers()"
    [scrollWindow]="false"
  >
    <table mat-table [dataSource]="leads">
      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>
          <button class="table-button" mat-icon-button>
            <mat-icon>download</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            (click)="getDownloadFilesOperation(row.id, row.numberInvoice!)"
          >
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameSacado ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="broker">
        <th mat-header-cell *matHeaderCellDef>Broker</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBroker ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="OperationType">
        <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
        <td mat-cell *matCellDef="let element">
          {{ getNameTypeOperation(element.operationType) ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Parcela">
        <th mat-header-cell *matHeaderCellDef>Parcela</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInstallment ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDateEnd">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberInvoice">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInvoice ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalAmount | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valueLiquid">
        <th mat-header-cell *matHeaderCellDef>
          Valor líquido
          <mat-icon
            matTooltip="Valor líquido considerando apenas o deságio. O valor pode mudar devido ao prazo de vencimento"
            >help</mat-icon
          >
        </th>

        <td mat-cell *matCellDef="let element">
          {{ (element.amountToPay | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Aditivo">
        <th mat-header-cell *matHeaderCellDef>Aditivo</th>
        <td mat-cell *matCellDef="let element">
          <p class="link" (click)="openDialogAditive(element.additive)">
            {{ element.additive ?? "N/A" }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="registerDrawee">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button>
            <mat-icon mat-button>assignment</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            (click)="openDialogRegisterDrawee(row)"
            matTooltip="Visualizar ou editar cadastro do sacado"
          >
            <mat-icon>assignment</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="handlePressRow(row)"
        [matTooltipPosition]="'left'"
        matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
        [matTooltipDisabled]="!row.hasPending"
        [ngClass]="{ 'table-text-warning': row.hasPending }"
      ></tr>
    </table>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner color="accent"></mat-spinner>
    </div>
    <div class="container-blankslate" *ngIf="!isLoading && leads.length == 0">
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </section>

  <div class="informations-status" *ngIf="customerStatus === 'PAID'">
    <div class="content-status">
      <p class="text">
        O que significa o status: <span class="bold">Pagos?</span>
      </p>
      <p class="text">
        Significa que você concluiu o processo e recebeu o valor líquido da sua
        antecipação.
      </p>
      <p class="text">
        <span class="bold">Obs:</span> Confira o depósito na conta corrente
        indicada para recebimento da antecipação. Caso não identifique o
        depósito contate diretamente o comprador pelo telefone na notificação
        enviada ao sacado com você em cópia. O título permanecerá nesse status
        até a data de vencimento. Após o vencimento, o título poderá passar para
        liquidados ou em cobrança.
      </p>
    </div>
  </div>
  <div class="informations-status" *ngIf="customerStatus === 'SETTLED'">
    <div class="content-status">
      <p class="text">
        O que significa o status: <span class="bold">Liquidado?</span>
      </p>
      <p class="text">
        Significa que o título foi liquidado, ou seja, a fomento recebeu o
        pagamento pela antecipação do título.
      </p>
      <p class="text">
        <span class="bold">Obs:</span> A operação foi finalizada e seu limite de
        crédito foi restabelecido.
      </p>
    </div>
  </div>
  <div class="informations-status" *ngIf="customerStatus === 'IN_COLLECTION'">
    <div class="content-status">
      <p class="text">
        O que significa o status: <span class="bold">Em cobrança?</span>
      </p>
      <p class="text">
        Significa que o título ainda não foi liquidado, ou seja, seu cliente
        está inadimplente e o comprador não recebeu o pagamento pela antecipação
        do título.
      </p>
      <p class="text">
        <span class="bold">Obs:</span> Contate o comprador do título com
        urgência e efetue o pagamento ao comprador para não prejudicar o seu
        crédito.
      </p>
    </div>
  </div>
  <app-aside [opened]="!!currentExpandedItem" (isClose)="handleCloseSidenav()">
    <div header>
      <p class="name-sacado">{{ currentExpandedItem?.nameSacado }}</p>

      <div class="installment-value-container">
        <div class="value-face-text-container">
          <mat-icon class="value-face">border_color</mat-icon>
          <p class="value-face">Valor de Face:</p>
        </div>
        <p class="value-face">
          {{ currentExpandedItem?.totalAmount | currency : "BRL" }}
        </p>
      </div>
    </div>
    <div header>
      <div class="settlement-history">
        <div class="title-container">
          <p class="title">Histórico de liquidação</p>
        </div>
        <div class="label">
          <div class="table-column">
            <mat-icon>calendar_today</mat-icon>
            <p class="value-text">Liquidação</p>
          </div>
          <p class="value-text">Valor</p>
        </div>

        <div
          class="label row"
          *ngFor="let payment of currentExpandedItem?.paymentsHistory"
        >
          <p>{{ payment.date }}</p>
          <p>{{ payment.value | currency : "BRL" }}</p>
        </div>
        <div class="line"></div>

        <p class="value">{{ totalPayment | currency : "BRL" }}</p>

        <div class="label">
          <div class="table-column">
            <mat-icon class="icon-alert">report_problem</mat-icon>
            <p class="value-total">Total em Aberto</p>
          </div>
          <p class="value-open">
            {{ currentExpandedItem?.remainingValue | currency : "BRL" }}
          </p>
        </div>
      </div>
    </div>
  </app-aside>
</div>
