<div class="main-container">
  <div class="title"></div>
  <mat-accordion>
    <mat-expansion-panel [hideToggle]="true">
      <mat-expansion-panel-header class="header-title">
        <mat-panel-title class="label-large text-color"
          >Comprador</mat-panel-title
        >

        <mat-panel-title class="text-color">Aditivo</mat-panel-title>
        <mat-panel-title class="text-color">Valor Bruto</mat-panel-title>
        <mat-panel-title class="text-color">Valor líquido</mat-panel-title>
        <mat-panel-title class="text-color">Ação</mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <ng-container *ngFor="let document of operationList">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="label-large">
            {{ document.installments[0].nameBuyer || "--" }}
          </mat-panel-title>

          <mat-panel-title
            [matTooltipPosition]="'left'"
            matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
            [matTooltipDisabled]="!document.installments[0].hasPending"
          >
            <p>
              {{ document.additive }}
            </p>
          </mat-panel-title>
          <mat-panel-title>
            {{ document.totalAmount | currency : "BRL" }}
          </mat-panel-title>

          <mat-panel-title>
            {{ document.amountToPay || 0 | currency : "BRL" }}
          </mat-panel-title>
          <mat-panel-title
            href="https://cloud.bry.com.br/scad/workflows/pendente"
            target="_blank"
            class="link"
          >
            Assinar</mat-panel-title
          >
        </mat-expansion-panel-header>

        <section class="container-table mat-elevation-z8" tabindex="0">
          <div class="table-container">
            <table mat-table [dataSource]="document.dataSource">
              <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef>Download</th>
                <td
                  mat-cell
                  *matCellDef="let row"
                  (click)="
                    getDownloadFilesOperation(row.id, row.numberInvoice!)
                  "
                >
                  <button class="table-button" mat-icon-button>
                    <mat-icon>download</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="sacados">
                <th mat-header-cell *matHeaderCellDef>Sacados</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameSacado }}
                </td>
              </ng-container>
              <ng-container matColumnDef="Broker">
                <th mat-header-cell *matHeaderCellDef>Broker</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.nameBroker }}
                </td>
              </ng-container>
              <ng-container matColumnDef="OperationType">
                <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
                <td mat-cell *matCellDef="let element">
                  {{ getNameTypeOperation(element.operationType) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Tipo</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.type }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dueDateEnd">
                <th mat-header-cell *matHeaderCellDef>Vencimento</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dueDate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="totalAmount">
                <th mat-header-cell *matHeaderCellDef>Valor de Face</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.totalAmount || 0 | currency : "BRL" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="valueLiquid">
                <th mat-header-cell *matHeaderCellDef>Valor líquido</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.amountToPay || 0 | currency : "BRL" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="registerDrawee">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-icon-button>
                    <mat-icon mat-button>assignment</mat-icon>
                  </button>
                </th>

                <td mat-cell *matCellDef="let row">
                  <button
                    mat-icon-button
                    (click)="openDialogRegisterDrawee(row)"
                    matTooltip="Visualizar ou editar cadastro do sacado"
                  >
                    <mat-icon>assignment</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </section>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner class="spinner" color="accent"></mat-spinner>
  </div>
  <div
    class="container-blankslate"
    *ngIf="!isLoading && operationList.length == 0"
  >
    <p>Nenhum dado encontrado com os filtros utilizados.</p>
  </div>
</div>
<div class="informations-status">
  <div class="content-status">
    <p class="text">
      O que significa o status:
      <span class="bold">Aguardando assinatura?</span>
    </p>
    <p class="text">
      Significa que você precisa assinar os documentos da operação para receber
      o valor pago da antecipação.
    </p>
    <p class="text">
      <span class="bold">Obs:</span> Clique em “Assinar” para ser redirecionado
      ao portal de assinatura Bry. É necessário fazer um cadastro gratuito uma
      única vez.
    </p>
    <p>
      <a
        href="https://blogbroadfactor.com/blog/o-que-voce-esta-assinando/"
        class="link"
        target="_blank"
        >Entenda o que está assinando.</a
      >
    </p>
  </div>
</div>
