import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import * as moment from 'moment';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { Output, EventEmitter } from '@angular/core';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { debounceTime, of, switchMap, take } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/utils/dateAdapter';
import { maskCnpj } from 'src/app/utils/string-to-cnpj-mask';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class FiltersComponent {
  formFilters!: FormGroup;
  @Output() filterEvent = new EventEmitter<FiltersInterface>();
  @Input() tabStatus!: string | null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private customerDashboardService: customerDashboardService) {
    this.formFilters = new FormGroup({
      nameActorOne: new FormControl(null, []),
      numberInvoice: new FormControl(null),
      dueDateInit: new FormControl(null),
      dueDateEnd: new FormControl(null),
    });

    this.formFilters.controls['nameActorOne'].valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getSearchFomento('ACTOR_ONE', data);
          }

          return of('');
        })
      )
      .subscribe();
  }

  formatedCnpjLabel(cnpj: string) {
    return maskCnpj(cnpj);
  }

  actorClientOptions: Record<
    string,
    { name: string; cnpj: string; id: string }[]
  > = {
    actorOne: [],
  };
  displayActorOne(cnpj?: string) {
    if (cnpj) {
      const { name } = this.actorClientOptions['actorOne'].find(
        (_: any) => _.cnpj === cnpj
      )!;

      return `${name} - ${maskCnpj(cnpj)}`;
    }

    return '';
  }

  getSearchFomento(field: 'ACTOR_ONE' | 'ACTOR_TWO' | 'ALL', filter?: string) {
    let type = this.formFilters.get('typeActorOne')?.value;
    if (field === 'ACTOR_TWO') {
      type = this.formFilters.get('typeActorTwo')?.value;
    }

    this.customerDashboardService
      .getSearchFomento(type, filter)
      .pipe(take(1))
      .subscribe((res) => {
        if (field === 'ACTOR_ONE') {
          this.actorClientOptions = {
            ...this.actorClientOptions,
            actorOne: res.content,
          };
        }
        if (field === 'ACTOR_TWO') {
          this.actorClientOptions = {
            ...this.actorClientOptions,
            actorTwo: res.content,
          };
        } else {
          this.actorClientOptions = {
            actorOne: res.content,
            actorTwo: res.content,
          };
        }
      });
  }

  getFormatedForm() {
    const data = this.formFilters.value;
    data['companyList'] = [];

    if (data['dueDateInit'] !== null) {
      data['dueDateInit'] = moment(data['dueDateInit']).format('YYYY-MM-DD');
    }
    if (data['dueDateEnd'] !== null) {
      data['dueDateEnd'] = moment(data['dueDateEnd']).format('YYYY-MM-DD');
    }

    // Check if field is number (CNPJ)
    data['companyList'].push({
      cnpj: data.nameActorOne ?? null,
      type: 'SACADO',
    });

    delete data['nameActorOne'];
    delete data['typeActorOne'];
    delete data['nameActorTwo'];
    delete data['typeActorTwo'];

    return data;
  }

  clearFilters() {
    this.formFilters.reset({ status: this.tabStatus });
    this.onSubmit();
  }
  onSubmit() {
    const data = this.getFormatedForm();
    this.filterEvent.emit(data);
  }
}
