import { catchError, Observable, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OperationLead } from '../interfaces/operacaoFilters';
import { AnalysisOperationsResponseInterface } from '../interfaces/analysis-operations.interface';
import { OperationsComponent } from '../pages/operations/operations.component';
import { FiltersInterface } from '../interfaces/filters.interface';
import { DocumentsInterface } from '../interfaces/documents-status.interface';
import { ModalAditiveInterface } from '../interfaces/modal-aditive.interface';
import { UpdateInstallments } from '../interfaces/updateInstallments.interface';
import { AuthService } from './auth.service';
import { CreditInfoInterface } from '../interfaces/creditInfo.interface';
import { UpdateInstallmentsInterface } from '../interfaces/insufficientCredit.interface';
import { ModalFeeInterface } from '../interfaces/modal-fee.interface';
import { CompanyInterface } from '../interfaces/company.interface';
import { DraweeInterface } from '../interfaces/invoiceData.interface';
import { FilteredCompanyResultInterface } from '../interfaces/filtered-company.interface';

@Injectable({
  providedIn: 'root',
})
export class customerDashboardService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getAll(
    pageIndex: number,
    pageSize: number,
    filters?: FiltersInterface
  ): Observable<{
    last: any;
    content: OperationLead[];
    totalElements: number;
  }> {
    const url =
      environment.operation_api + `open/installment/${pageIndex}/${pageSize}`;
    const { cnpj } = JSON.parse(window.sessionStorage.getItem('auth')!);
    const filterCnpj = filters?.companyList
      ? filters.companyList[0].cnpj
      : null;
    const companyList = [{ cnpj: filterCnpj, type: 'SACADO' }];

    companyList.push({ cnpj, type: 'LEAD' });

    return this.http.post<{
      last: any;
      content: OperationLead[];
      totalElements: number;
    }>(url, {
      status: filters?.status || null,
      responsible: null,
      type: null,
      numberInvoice: null,
      createdAtInit: null,
      createdAtEnd: null,
      dueDateInit: null,
      dueDateEnd: null,
      ...filters,
      companyList,
    });
  }

  getSearchFomento(
    type: string,
    filter?: string
  ): Observable<{ content: any[] }> {
    const defaultType = 'SACADO';
    return this.http.get<{ content: any[] }>(
      environment.register_api +
        `secure-api/company/getall/${defaultType}/0/50/ContaAzul?filter=${
          filter || ''
        }`
    );
  }
  getSearchSacado(filter?: string): Observable<{ content: any[] }> {
    return this.http.get<{ content: any[] }>(
      environment.register_api +
        `secure-api/company/getall/SACADO/0/50/?filter=${filter || ''}`
    );
  }

  getCompanies(type = 'SACADO', filter = '') {
    return this.http.get<FilteredCompanyResultInterface>(
      environment.register_api +
        `secure-api/company/getall/${type}/0/50/?filter=${filter}`
    );
  }

  getOperationsInAnalysis(
    filters?: FiltersInterface
  ): Observable<AnalysisOperationsResponseInterface> {
    const url = environment.operation_api + `open/installment/ANALYSIS/0/50`;
    const { cnpj } = JSON.parse(window.sessionStorage.getItem('auth')!);
    return this.http.post<AnalysisOperationsResponseInterface>(url, {
      status: null,
      responsible: null,
      type: null,
      numberInvoice: null,
      createdAtInit: null,
      createdAtEnd: null,
      dueDateInit: null,
      dueDateEnd: null,
      companyList: [
        {
          cnpj,
          type: 'LEAD',
        },
        {
          cnpj: null,
          type: null,
        },
      ],
    });
  }

  getDownloadFilesOperation(id: string): any {
    return this.http.get(
      environment.operation_api + `api/invoice/document/download/all/${id}`,
      {
        headers: {
          skip: 'true',
          Authorization: `Bearer ${environment.operation_api_token}`,
        },
        responseType: 'blob',
      }
    );
  }

  getDocumentOperation(): Observable<DocumentsInterface> {
    const url = environment.operation_api + `open/installment/DOCUMENT/0/50`;
    const { cnpj } = JSON.parse(window.sessionStorage.getItem('auth')!);
    return this.http.post<DocumentsInterface>(url, {
      status: null,
      type: null,
      numberInvoice: null,
      responsible: null,
      dueDateInit: null,
      dueDateEnd: null,
      createdAtInit: null,
      createdAtEnd: null,
      companyList: [
        {
          cnpj,
          type: 'LEAD',
        },
        {
          cnpj: null,
          type: null,
        },
      ],
    });
  }
  getModalAditive(additive: string): Observable<ModalAditiveInterface[]> {
    const url = environment.aditive_api + `collect/?additive=${additive}`;
    return this.http.post<ModalAditiveInterface[]>(url, []);
  }
  updateInstallments(
    data: UpdateInstallments
  ): Observable<UpdateInstallmentsInterface> {
    return this.http
      .put(environment.operation_api + 'open/installment', data)
      .pipe(
        catchError((err) => {
          console.log(err);
          return throwError(err);
        })
      );
  }

  getCompanyByCnpj(cnpj: string): Observable<CompanyInterface> {
    const url =
      environment.register_api + `secure-api/company/getCompanyByCnpj/${cnpj}`;
    return this.http.get<CompanyInterface>(url);
  }

  getCreditInfo(): Observable<CreditInfoInterface> {
    const { auth } = this.authService.getAuth();
    const url =
      environment.register_api +
      `secure-api/company/${auth}/config/monthlyPlan`;
    return this.http.get<CreditInfoInterface>(url);
  }
  getEmoluments(data: string[]): Observable<ModalFeeInterface> {
    const url = environment.operation_api + 'open/installment/emoluments/';
    return this.http.post<ModalFeeInterface>(url, data);
  }
}
