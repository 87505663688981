import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { take, map, finalize } from 'rxjs';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { customerDashboardService } from 'src/app/services/customer-dashboard.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddInvoiceModalComponent } from 'src/app/modules/invoice/components/add-invoice-modal/add-invoice-modal.component';
import { DraweeService } from 'src/app/modules/invoice/services/drawee.service';

@Component({
  selector: 'app-registered-titles',
  templateUrl: './registered-titles.component.html',
  styleUrls: ['./registered-titles.component.scss'],
})
export class RegisteredTitlesComponent implements OnInit, AfterViewInit {
  isLoading = false;
  leads: OperationLead[] = [];
  customerStatus = 'REGISTERED';
  filters?: FiltersInterface = { status: this.customerStatus };
  displayedColumns: string[] = [
    'download',
    'sacado',
    'broker',
    'Parcela',
    'dueDateEnd',
    'type',
    'numberInvoice',
    'totalAmount',
    'valueLiquid',
  ];
  dataSource = new MatTableDataSource<OperationLead>();
  lastPage = false;
  pageIndex: number = 0;
  pageSize: number = 20;
  draftTitleText: string = 'Títulos em rascunho';
  draftTitles: number = 0;
  constructor(
    private customerDashboardService: customerDashboardService,
    public dialog: MatDialog,
    public router: Router,
    private draweeService: DraweeService
  ) {}


  ngOnInit(): void {
    this.getCustomers();
  }

  ngAfterViewInit() {
    this.customerDashboardService
      .getAll(0, 1, { status: 'SKETCH' })
      .subscribe(({ totalElements }) => {
        this.draftTitles = totalElements;
        this.customerTitlesChange();
      });
  }

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.customerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }

  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }
  openDialog() {
    const dialogRef = this.dialog.open(AddInvoiceModalComponent, {});
  }

  customerTitlesChange() {
    if (this.draftTitles === 0) {
      this.draftTitleText = 'Você não possui títulos em rascunho.';
    } else {
      this.draftTitleText = `Você possui ${this.draftTitles} título(s) em rascunho. Clique para exibir.`;
    }
  }
  editInvoice(id: string) {
    this.draweeService.getInvoiceById(id).subscribe({
      next: (invoice) => {
        this.draweeService.invoice.next(invoice);
        this.router.navigate(['cadastro', 'dados-nota-fiscal']);
      },
    });
  }
  changeCustomersStatus() {
    if (this.draftTitles === 0) return;
    if (this.customerStatus === 'REGISTERED') {
      this.displayedColumns.unshift('edit');

      this.customerStatus = 'SKETCH';
      this.draftTitleText =
        'Clique para retornar aos títulos cadastrados completos.';
    } else {
      this.displayedColumns.splice(this.displayedColumns.indexOf('edit'), 1);
      this.customerStatus = 'REGISTERED';
      this.customerTitlesChange();
    }
    this.lastPage = false;
    this.pageIndex = 0;
    this.getCustomers({ status: this.customerStatus });
  }

  getCustomers(filters?: FiltersInterface) {
    if (filters) {
      this.filters = {
        status: this.customerStatus,
        ...filters,
      };
    }

    if (!this.lastPage) {
      this.isLoading = true;
      this.customerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest}) => {
            const invoicesWithWrongInstallments = this.checkInvoicesWithWrongInstallments(content);

            if(invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(invoice, invoiceNumber);
                });
              })
            }
            return { content, ...rest };
        }),
        finalize(() => {
          this.isLoading = false;
        }))
        .subscribe({
          next: (data) => {
            if (this.pageIndex === 0) this.leads = data.content;
            else this.leads = this.leads.concat(data.content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            this.lastPage = data.last;
          },
        });
    }
  }

  checkInvoicesWithWrongInstallments(invoice: OperationLead[]): OperationLead['numberInvoice'][] {
    return invoice.filter(invoice => {
      const installmentAsString = String(invoice.numberInstallment);
      const hasWrongInstallment = installmentAsString.startsWith("0") && installmentAsString.charAt(1) === "/";
      return hasWrongInstallment;
    }).map(invoice => invoice.numberInvoice);
  }


  correctInvoiceInstallments(invoice: OperationLead, invoiceNumber: OperationLead['numberInvoice']) {
      if(invoice.numberInvoice === invoiceNumber) {
        const numberInstallmentAsString = String(invoice.numberInstallment);
        const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
        const correctNumber = +firstHalf + 1;
        const correctInstallmentNumber = String(correctNumber).concat('/', secondHalf);
        return { ...invoice, numberInstallment: correctInstallmentNumber };
      }
     return { ...invoice };
  }
}
