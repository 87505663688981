<div class="main-container">
  <app-filters
    [tabStatus]="null"
    (filterEvent)="handleSubmit($event)"
  ></app-filters>
  <section
    class="table-container mat-elevation-z8"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getCustomers()"
    [scrollWindow]="false"
  >
    <table mat-table [dataSource]="leads">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [disabled]="row.hasPending"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>
          <button class="table-button" mat-icon-button>
            <mat-icon>download</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            (click)="getDownloadFilesOperation(row.id, row.numberInvoice!)"
          >
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="registerDrawee">
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button>
            <mat-icon mat-button>assignment</mat-icon>
          </button>
        </th>

        <td mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            (click)="openDialogRegisterDrawee(row)"
            matTooltip="Visualizar ou editar cadastro do sacado"
          >
            <mat-icon>assignment</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameSacado ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="broker">
        <th mat-header-cell *matHeaderCellDef>Broker</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBroker ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="OperationType">
        <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
        <td mat-cell *matCellDef="let element">
          {{ getNameTypeOperation(element.operationType) ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Parcela">
        <th mat-header-cell *matHeaderCellDef>Parcela</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInstallment ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="dueDateEnd">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberInvoice">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInvoice ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="totalAmount">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalAmount | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="valueLiquid">
        <th mat-header-cell *matHeaderCellDef>
          Valor líquido
          <mat-icon
            matTooltip="Valor líquido considerando apenas o deságio. O valor pode mudar devido ao prazo de vencimento"
            >help</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ (element.amountToPay | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [matTooltipPosition]="'left'"
        matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
        [matTooltipDisabled]="!row.hasPending"
        [ngClass]="{ 'table-text-warning': row.hasPending }"
      ></tr>
    </table>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner color="accent"></mat-spinner>
    </div>
    <div class="container-blankslate" *ngIf="!isLoading && leads.length == 0">
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </section>

  <mat-toolbar class="mat-elevation-z4 selection-header">
    <button
      mat-icon-button
      (click)="clearSelection()"
      *ngIf="selection.selected.length > 0"
    >
      <mat-icon>close</mat-icon>
    </button>
    <span *ngIf="selection.selected.length > 0">
      {{ selection.selected.length }}
      <span *ngIf="selection.selected.length === 1"> Título selecionado </span>
      <span *ngIf="selection.selected.length > 1"> Títulos selecionados </span>
    </span>
    <span class="example-spacer"></span>
    <button
      mat-flat-button
      (click)="openDialog()"
      [disabled]="selection.selected.length === 0"
      class="button-simulate"
      [ngStyle]="{
        'background-color':
          selection.selected.length > 0 ? '#CC3179' : 'rgba(204, 49, 121, 0.38)'
      }"
    >
      Simular antecipação
    </button>
  </mat-toolbar>
</div>
